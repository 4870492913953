import routerOptions0 from "/home/forge/app-test.hd-institute.com/releases/20240814140220/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/forge/app-test.hd-institute.com/releases/20240814140220/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}