import { default as about04KjmDDi6SMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/about.vue?macro=true";
import { default as loginXqHtJ8mthvMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/auth/login.vue?macro=true";
import { default as faqAp4EzGDEzoMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/faq.vue?macro=true";
import { default as index9wk8KCxb6TMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/index.vue?macro=true";
import { default as _1_46startLAKmXIeoj4Meta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionZpxpfROpADMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishJy2A7DhbtgMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/3.finish.vue?macro=true";
import { default as formZmvblTJg4YMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form.vue?macro=true";
import { default as homezeoT9HYtkVMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/home.vue?macro=true";
import { default as onboardingPGu5nXmmy6Meta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/onboarding.vue?macro=true";
import { default as prepare4TyhXqXGjfMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teams8yvVoxyyG4Meta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teamsfsWxP9vj9cMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionfXzHsOHmwvMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46resultsLVmDaEujrZMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectU50thr1qwUMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/select.vue?macro=true";
import { default as finishlHZg257J0CMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/finish.vue?macro=true";
import { default as homey0ATBtd8McMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/home.vue?macro=true";
import { default as onboardingsxnmOqVBeuMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/onboarding.vue?macro=true";
import { default as prepare0kldtqfHY9Meta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/prepare.vue?macro=true";
import { default as resumeYr6OEBmAWuMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/resume.vue?macro=true";
import { default as scani2htmlBADtMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/scan.vue?macro=true";
import { default as select54BO3FqWptMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/select.vue?macro=true";
import { default as settingsOrbVWRjgf5Meta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/settings.vue?macro=true";
import { default as systemRiF9N5dvVTMeta } from "/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/system.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: about04KjmDDi6SMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginXqHtJ8mthvMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: index9wk8KCxb6TMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form",
    path: "/quiz/form",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form.vue").then(m => m.default || m),
    children: [
  {
    name: "quiz-form-1.start",
    path: "1.start",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-2.session",
    path: "2.session",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-form-3.finish",
    path: "3.finish",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "quiz-home",
    path: "/quiz/home",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: "quiz-onboarding",
    path: "/quiz/onboarding",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "quiz-prepare",
    path: "/quiz/prepare",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-1.create-teams",
    path: "/quiz/quiz/1.create-teams",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-2.teams",
    path: "/quiz/quiz/2.teams",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-3.session",
    path: "/quiz/quiz/3.session",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: "quiz-quiz-4.results",
    path: "/quiz/quiz/4.results",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: "quiz-select",
    path: "/quiz/select",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: "scan-finish",
    path: "/scan/finish",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: "scan.home",
    path: "/scan/home",
    meta: homey0ATBtd8McMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: "scan-onboarding",
    path: "/scan/onboarding",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "scan.prepare",
    path: "/scan/prepare",
    meta: prepare0kldtqfHY9Meta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: "scan.resume",
    path: "/scan/resume",
    meta: resumeYr6OEBmAWuMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: "scan-scan",
    path: "/scan/scan",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: "scan-select",
    path: "/scan/select",
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsOrbVWRjgf5Meta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: systemRiF9N5dvVTMeta || {},
    component: () => import("/home/forge/app-test.hd-institute.com/releases/20240814140220/pages/system.vue").then(m => m.default || m)
  }
]